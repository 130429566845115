// import { getFluidImage } from '@/storyblok/utils/getFluidImage'
import { getLink } from '@/storyblok/utils/getLink'
// import { getMedia } from '@/storyblok/utils/getMedia'
// import { getRichHeading } from '@/storyblok/utils/getRichHeading'
import { getRichText } from '@/storyblok/utils/getRichText'
import { getImageArray } from '@/storyblok/utils/getImageArray'
// import { getSlug } from '@/utils/getSlug'
// import { linkResolver } from '@/storyblok/utils/linkResolver'

/**
 * Process prismic block data that can be passed to components as props
 *
 * @param {array} data
 * @param {object} location
 */

export const useBloks = (data, location = {}) => {
  const blocks = []

  data.forEach((block, blockIndex) => {
    if (block) {
      // block object
      const output = {
        type: block.component,
        id: block._uid || `block-${blockIndex}`,
        location: location,
        sbEditable: block, // for SbEditable component
      }

      // hero
      if (block.component === 'hero') {
        output.intro = getRichText(block.intro)

        output.text01 = block.text_01 // 'Best-in-show ground floor retail'
        output.text02 = block.text_02 // '5 Commercial Floors'
        output.text03 = block.text_03 // 'Chippendale Sydney'
        output.text04 = block.text_04 // 'Be part of Sydney’s Innovation Precinct'
        output.text05 = block.text_05 // 'Scheduled for completion early 2021'
        output.text06 = block.text_06 // '5 mins from Central Station'

        output.contactCta = block.contact_cta // 'Enquire now'

        const imageArrayConfig = { maxWidth: 500 }
        output.images01 = getImageArray(block.image_array_01, imageArrayConfig)
        output.images02 = getImageArray(block.image_array_02, imageArrayConfig)
        output.images03 = getImageArray(block.image_array_03, imageArrayConfig)
        output.images04 = getImageArray(block.image_array_04, imageArrayConfig)
        output.images05 = getImageArray(block.image_array_05, imageArrayConfig)
      }

      // body text
      else if (block.component === 'body_text') {
        output.heading = block.heading
        output.children = getRichText(block.body)
      }

      // text and links
      else if (block.component === 'text_and_links') {
        output.textHeading = block.text_heading
        output.textContent = getRichText(block.text_body)
        output.linksHeading = block.links_heading
        output.linkGroups = []
        output.textStyle = block.text_style

        block.links_group.forEach((group) => {
          const links = []

          group.links.forEach((item) => {
            links.push(
              getLink({
                id: item._uid,
                title: item.title,
                link: item.link,
              })
            )
          })

          output.linkGroups.push({
            id: group._uid,
            heading: group.heading,
            links: links,
          })
        })
      }

      // add to output array
      blocks.push(output)
    }
  })

  return blocks
}
