import React from 'react'
import PropTypes from 'prop-types'
import SbEditable from 'storyblok-react'

// const types = {
//   example: Example,
// }

const BlockRouter = ({ blocks, types }) => {
  const output = []
  let prev = 'none'

  blocks.forEach((block, index) => {
    const Component = types[block.type]
    const next = blocks[index + 1] ? blocks[index + 1].type : 'none'

    if (Component) {
      output.push(
        <SbEditable content={block.sbEditable} key={block.id}>
          <Component prevblock={prev} nextblock={next} {...block} />
        </SbEditable>
      )
      prev = block.type
    }
  })

  return output
}

BlockRouter.defaultProps = {
  blocks: [],
  types: {},
}

BlockRouter.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  types: PropTypes.object,
}

export { BlockRouter }
