// extracted by mini-css-extract-plugin
export var TextAndLinks = "s_bt";
export var linkGroup = "s_bv";
export var linkGroup_heading = "s_bw";
export var links = "s_bx";
export var links__item = "s_by";
export var link = "s_bz";
export var textContent = "s_bB";
export var defaultTextStyle = "s_bC";
export var largeTextStyle = "s_bD";
export var aniSpin = "s_d";