import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Img } from '@/components/Img'
import { useFader } from '@/hooks/useFader'
import * as styles from './HeroImage.styles.scss'

const HeroImage = ({ images, shape }) => {
  const faderRef = useRef()

  useFader(faderRef, {
    selector: `.${styles.image}`,
    duration: 1,
    delay: 2,
  })

  return (
    <div className={styles.HeroImage}>
      {!!images?.length && (
        <div className={styles.images} ref={faderRef}>
          {images.map((image) => {
            return (
              <div className={styles.image} key={image.id}>
                <Img {...image} isFull objectFit="cover" />
              </div>
            )
          })}
        </div>
      )}
      <div className={styles.shape} data-shape={shape} />
    </div>
  )
}

HeroImage.defaultProps = {
  shape: 'rectangle',
}

HeroImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  shape: PropTypes.oneOf([
    'rectangle',
    'circleRight',
    'circleLeft',
    'circleBottom',
    'circleTop',
  ]),
}

export { HeroImage }
