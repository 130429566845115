import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import * as styles from './BodyText.styles.scss'

const BodyText = ({ children, heading }) => {
  return (
    <div className={styles.BodyText}>
      <Container>
        {heading && <h3 className={styles.heading}>{heading}</h3>}
        <div className={styles.inner}>
          <RichText>{children}</RichText>
        </div>
      </Container>
    </div>
  )
}

BodyText.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.node,
}

export { BodyText }
