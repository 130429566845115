import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { RichText } from '@/components/RichText'
import { SplitLayout } from '@/components/SplitLayout'
import { Link } from '@/components/Link'
import * as styles from './TextAndLinks.styles.scss'

const TextAndLinks = ({
  textHeading,
  textContent,
  linksHeading,
  linkGroups,
  textStyle,
}) => {
  return (
    <div className={styles.TextAndLinks}>
      <SplitLayout
        column01={{
          title: textHeading,
          body: textContent ? (
            <div
              className={classnames(styles.textContent, {
                [styles[`${textStyle}TextStyle`]]: !!textStyle,
              })}
            >
              <RichText>{textContent}</RichText>
            </div>
          ) : null,
        }}
        column02={{
          title: linksHeading,
          body: (
            <div>
              {linkGroups.map((group) => {
                return (
                  <div key={group.id} className={styles.linkGroup}>
                    {group.heading && (
                      <div className={styles.linkGroup_heading}>
                        {group.heading}
                      </div>
                    )}
                    <ul className={styles.links}>
                      {group.links.map((item) => {
                        return (
                          <li key={item.id} className={styles.links__item}>
                            <Link
                              to={item.url}
                              download={item.download}
                              className={styles.link}
                            >
                              {item.title}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
          ),
        }}
      />
    </div>
  )
}

TextAndLinks.defaultProps = {
  textStyle: 'default',
}

TextAndLinks.propTypes = {
  textHeading: PropTypes.node,
  textContent: PropTypes.node,
  linksHeading: PropTypes.node,
  linkGroups: PropTypes.arrayOf(PropTypes.object),
  textStyle: PropTypes.oneOf(['default', 'large']),
}

export { TextAndLinks }
