import { getFluidImage } from '@/storyblok/utils/getFluidImage'

/**
 * Get Image array from Storyblok
 *
 * See options here:
 * https://github.com/bejamas/gatsby-storyblok-image
 */

export const getImageArray = (imageArray, options = { maxWidth: 1000 }) => {
  const output = []

  imageArray.forEach((item) => {
    if (item.image) {
      output.push({
        id: item.image.id,
        ...getFluidImage(item.image.filename, options),
      })
    }
  })

  return output
}
