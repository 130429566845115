import { useEffect } from 'react'
import { gsap } from 'gsap'

/**
 * Hook for gsap based cross fader
 *
 * https://stackoverflow.com/a/52119497
 *
 * @usage useFader(ref, { selector: `.${styles.item}`, duration: 1 })
 */

const defaultOptions = {
  duration: 5,
  delay: 1,
  selector: 'img',
  isPlaying: true,
}

export const useFader = (ref, options) => {
  const mergedOptions = { ...defaultOptions, ...options }
  const { duration, delay, selector, isPlaying } = mergedOptions

  useEffect(() => {
    const items = gsap.utils.toArray(ref.current.querySelectorAll(selector))

    // don't run if there is only one image
    if (!items || items.length <= 1) return

    const tl = gsap.timeline({
      repeat: -1,
      delay: duration + delay,
      repeatDelay: delay,
    })

    // initial state
    gsap.set(items, { autoAlpha: 0, scale: 1.1 })

    if (isPlaying) {
      // animate the first image in (don't put this in the timeline because it's different - there's no image showing behind it)
      gsap.to(items[0], { duration: duration, autoAlpha: 1, scale: 1 })

      // loop through the rest of the images and build their animations
      for (let i = 1; i < items.length; i++) {
        tl.to(
          items[i],
          { duration: duration, autoAlpha: 1, scale: 1 },
          (i - 1) * (duration + delay)
        )
        // after the new image animates in, we can hide the old one that's behind it (aids browser performance)
        tl.set(items[i - 1], { autoAlpha: 0, scale: 1.05 })
      }

      if (items.length > 1) {
        // take the first image and change the zIndex so that it's on top of the last image, and animate it in.
        tl.set(items[0], { zIndex: 100 })
        tl.to(items[0], {
          duration: duration,
          autoAlpha: 1,
          scale: 1,
          delay: delay,
          immediateRender: false,
        })
      }
    }

    return () => {
      if (tl) tl.kill()
      gsap.set(items, { clearProps: 'all' })
    }
  }, [ref, duration, delay, selector, isPlaying])
}
