import React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store/browserState'
import * as styles from './Hero.styles.scss'

import { RichText } from '@/components/RichText'
import { HeroImage } from './HeroImage'

import LogoTextSvg from './images/logo-circle-text.inline.svg'
import LogoBaseSvg from './images/logo-circle-base.inline.svg'
import WindowTopLeftSvg from './images/window-top-left.inline.svg'
import WindowTopRightSvg from './images/window-top-right.inline.svg'

const Hero = ({
  intro,
  text01,
  text02,
  text03,
  text04,
  text05,
  text06,
  contactCta,
  images01,
  images02,
  images03,
  images04,
  images05,
}) => {
  const { device } = useRecoilValue(browserState)

  return (
    <div className={styles.Hero}>
      <div className={styles.rowA}>
        <div className={styles.rowA__01}>
          <div className={styles.intro}>
            <RichText>{intro}</RichText>
          </div>
        </div>
        <div className={styles.rowA__02}>
          <div className={styles.logo}>
            <div className={styles.logo__base}>
              <LogoBaseSvg />
            </div>
            <div className={styles.logo__text}>
              <LogoTextSvg />
            </div>
          </div>
        </div>
        <div className={styles.rowA__03}>
          <div className={styles.rowA__03__top}>
            <div className={styles.rowA__03__top__left}>
              <HeroImage images={images01} shape="circleBottom" />
            </div>
            <div className={styles.rowA__03__top__right} />
          </div>
          <div className={styles.rowA__03__bottom}>
            <div className={styles.rowA__03__bottom__text}>
              <div className={styles.label}>{text01}</div>
            </div>
          </div>
        </div>
        <div className={styles.rowA__04}>
          <HeroImage images={images02} shape="circleLeft" />
        </div>
      </div>
      <div className={styles.rowB}>
        <div className={styles.rowB__01}>
          <div className={styles.rowB__01__top} />
          <div className={styles.rowB__01__middle}>
            <span className={styles.heading}>{text03}</span>
          </div>
          <div className={styles.rowB__01__bottom} />
        </div>
        <div className={styles.rowB__02} />
        <div className={styles.rowB__03}>
          <div className={styles.rowB__03__container}>
            <div className={styles.rowB__03__col}>
              <div className={styles.window__top} />
              <div className={styles.window__main} />
              <div className={styles.window__bottom} />
            </div>
            <div className={styles.rowB__03__col}>
              <div className={styles.window}>
                <div className={styles.window__top}>
                  <WindowTopLeftSvg />
                </div>
                <div className={styles.window__main}>
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                </div>
              </div>
            </div>
            <div className={styles.rowB__03__col}>
              <div className={styles.window}>
                <div className={styles.window__top}>
                  <WindowTopRightSvg />
                </div>
                <div className={styles.window__main}>
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                </div>
              </div>
            </div>
            <div className={styles.rowB__03__col}>
              <div className={styles.window}>
                <div className={styles.window__top}>
                  <WindowTopLeftSvg />
                </div>
                <div className={styles.window__main}>
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                </div>
              </div>
            </div>
            <div className={styles.rowB__03__col}>
              <div className={styles.window}>
                <div className={styles.window__top}>
                  <WindowTopRightSvg />
                </div>
                <div className={styles.window__main}>
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                  <div className={styles.window__main__part} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rowB__03__labels}>
            <div className={styles.rowB__03__labels__top}>
              <div className={styles.label}>{text02}</div>
            </div>
            <div className={styles.rowB__03__labels__middle} />
            <div className={styles.rowB__03__labels__bottom}>
              <div className={styles.label}>{text04}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rowC}>
        <div className={styles.rowC__01}>
          <HeroImage
            key={device}
            images={device === 'mobile' ? images02 : images03}
            shape="circleRight"
          />
        </div>
        <div className={styles.rowC__02}>
          <div className={styles.rowC__02__col}>
            <div className={styles.rowC__02__col__container}>
              <div className={styles.rowC__02__text}>
                <div className={styles.label}>{text05}</div>
              </div>
            </div>
          </div>
          <div className={styles.rowC__02__col} />
          <div className={styles.rowC__02__col}>
            <div className={styles.rowC__02__col__container}>
              <div className={styles.rowC__02__text}>
                <div className={styles.label}>{text06}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rowC__03}>
          <HeroImage images={images04} shape="circleBottom" />
        </div>
        <div className={styles.rowC__04}>
          <div className={styles.rowC__04__side} />
          <div className={styles.rowC__04__middle}>
            <div className={styles.rowC__04__image}>
              <HeroImage images={images05} shape="circleTop" />
            </div>
            <div className={styles.rowC__04__text}>
              <span className={styles.heading}>{contactCta}</span>
            </div>
          </div>
          <div className={styles.rowC__04__side} />
        </div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  intro: PropTypes.node,
  text01: PropTypes.string,
  text02: PropTypes.string,
  text03: PropTypes.string,
  text04: PropTypes.string,
  text05: PropTypes.string,
  text06: PropTypes.string,
  contactCta: PropTypes.string,
  images01: PropTypes.arrayOf(PropTypes.object),
  images02: PropTypes.arrayOf(PropTypes.object),
  images03: PropTypes.arrayOf(PropTypes.object),
  images04: PropTypes.arrayOf(PropTypes.object),
  images05: PropTypes.arrayOf(PropTypes.object),
}

export { Hero }
